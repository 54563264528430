import { createAxiosInstance, createRequestHelpers, isDev } from "../instanceHelpers";


const axiosInstance = createAxiosInstance(
  isDev() ? ':8080/admin' : '/sqadmin/admin'
);

const request = createRequestHelpers(axiosInstance);

export { resolveRequest } from '../instanceHelpers';

export default request;
