import React, { createContext, useReducer, useEffect, useState  } from 'react';
import { ConfigProvider } from "antd";
import { IntlProvider } from "react-intl";
import { useSelector } from "react-redux";
import { ThemeProvider } from "styled-components";
import themes from "@iso/config/theme/theme.config";
import AppLocale from "@iso/config/translation";
import { resolveRequestUM, userManagementApi } from '@iso/config/apiUM';
import facilityApi from './config/api/facilityManagementApi';

export const AppContext = createContext();
window.userListReteriesCount = 1;

export default function AppProvider({ children }) {
  let usersListFetchInterval = null;

  const { locale } = useSelector((state) => state.LanguageSwitcher.language);
  const { themeName } = useSelector(
    (state) => state.ThemeSwitcher.changeThemes
  );
  const [hseMetricsCount, setHseMetricsCount] = useState();
  const currentAppLocale = AppLocale[locale];

  const [appState, setAppState] = useReducer(
    (prev, next) => ({ ...prev, ...next }),
    {}
  );
  const [raiseIncidentState, setRaiseIncidentState] = useReducer(
    (prev, next) => ({ ...prev, ...next }),
    {}
  );

  const [allUsersList, setAllUsers] = useState([]);
  const [activeUsersList, setActiveUsers] = useState([]);
  const [orgLevels, setOrgLevels] = useState([]);

  const fetchUsers = async () => new Promise(async resolve => {
    const promise1 = userManagementApi.getEmployeesBrief({ fetchAll: true });
    const promise2 = userManagementApi.getEmployeesBrief({ fetchAll: false });
    const [request1, request2] = await Promise.allSettled([
      resolveRequestUM(promise1),
      resolveRequestUM(promise2)
    ]);
    const { data: data1, error: error1 } = request1.value;
    const { data: data2, error: error2 } = request2.value;

    const error = error1 || error2;

    if (!error1) {
      setAllUsers(data1.map(option => ({
          ...option,
          id: option.id,
          name: option.firstName + (option.lastName ? ` ${option.lastName}` : '')
      })));
    }

    if (!error2) {
      setActiveUsers(data2.map(option => ({
          ...option,
          id: option.id,
          name: option.firstName + (option.lastName ? ` ${option.lastName}` : '')
      })));
    }

    resolve({ error });
  })

  const fetchOrgLevels = async () => {
    const promise = facilityApi.getLevels();
    const { data, error } = await resolveRequestUM(promise);
    if (error) return;
    setOrgLevels(data?.levels || []);
  }

  const retryUsersListAPICall = async (resetCount) => {
    if (resetCount) window.userListReteriesCount = 1;

    const { error } = await fetchUsers();
    
    if (error && window.userListReteriesCount <= 3) {
      window.userListReteriesCount += 1;
      retryUsersListAPICall();
    }
  }

  useEffect(() => {
    const token = localStorage.getItem('token');

    if (token) {
      retryUsersListAPICall();
      fetchOrgLevels();
    }
  }, [localStorage.getItem('token')])

  return (
    <ConfigProvider locale={currentAppLocale.antd}>
      <IntlProvider
        locale={currentAppLocale.locale}
        messages={currentAppLocale.messages}
      >
        <AppContext.Provider value={{
          appState,
          setAppState, 
          raiseIncidentState, 
          setRaiseIncidentState,
          hseMetricsCount,
          setHseMetricsCount,
          fetchUsers,
          allUsersList,
          activeUsersList,
          retryUsersListAPICall,
          orgLevels
        }}>
          <ThemeProvider theme={themes[themeName]}>
            {children}
          </ThemeProvider>
        </AppContext.Provider>
      </IntlProvider>
    </ConfigProvider>
  );
}
