import request from './api'
import requestV3 from './apiv3'

const facilityApi = {
  getFacilities: () => request.get('/fm/facility/fetch'),
  createFacility: (data) => request.post('/fm/facility/create', data),
  updateFacility: (data) => request.post('/fm/facility/create', data),
  deleteFacility: (facilityId) => request.delete(`/fm/facility/${facilityId}`),
  createDepartment: (data) => request.post('/fm/department/create', data),
  deleteDepartment: (departmentId) => request.delete(`/fm/department/${departmentId}`),
  updateDepartment: (data) => request.post('/fm/department/create', data),
  createLocation: (data) => request.post('/fm/location/create', data),
  updateLocation: (data) => request.post('/fm/location/create', data),
  deleteLocation: (locationId) => request.delete(`/fm/location/${locationId}`),
  getDefinition: () => requestV3.get('/ohl/definition'),
  getLevels: () => requestV3.get('/ohl/levels'),
  exportFacilityData: () => requestV3.get('/ohl/export'),
  fetchNodeLevel: (levelName) => requestV3.get(`/ohl/node/${levelName}`),
  fetchNodeLevelById: (level, params) => requestV3.get(`/ohl/node/${level}${params}`),
}

export default facilityApi