import React, { createContext, useEffect, useState } from 'react';
import { resolveRequest, appConfigApi } from "@apis";

export const CustomLocalTranslationContext = createContext({});

const sidebarLabels = {
  "Home": true,
  "Actions": true,
  "Tasks": true,
  "Issues & Observations": true,
  "Incidents": true,
  "Inspections": true,
  "EHS Library": true,
  "Administration": true
};

export const CustomLocalTranslationProvider = ({ children }) => {
  const [appConfig, setAppConfig] = useState({});
  const [translations, setTranslations] = useState({});
  const [isFetchingCustomLocalTranslations, setIsFetchingCustomLocalTranslations] = useState(false);
  const [appConfigLongestTranslationTextLength, setAppConfigLongestTranslationTextLength] = useState(false);

  const fetchTranslations = async () => {
    setIsFetchingCustomLocalTranslations(true);

    const promise = appConfigApi.fetchAppConfig();
    const { data = [], error } = await resolveRequest(promise);

    setIsFetchingCustomLocalTranslations(false);

    if (error) return;

    let translationTextLength = 0;
    const appConfigMap = {};
    const translationsMap = data?.reduce((m, d) => { 
      const name = d.aliasName || d.name;
      m[d.name] = name;

      appConfigMap[d.name] = d;

      if (sidebarLabels[d.name] && translationTextLength < name.length) translationTextLength = name.length;

      return m;
    }, {});

    setAppConfigLongestTranslationTextLength(translationTextLength);

    setTranslations(translationsMap);
    setAppConfig(appConfigMap);
  }

  useEffect(() => {
    const token = localStorage.getItem('token');

    if (token) fetchTranslations();
  }, [])

  return (
    <CustomLocalTranslationContext.Provider value={{
      translations,
      setTranslations,
      isFetchingCustomLocalTranslations,
      fetchCustomLocalTranslations: fetchTranslations,
      appConfigLongestTranslationTextLength,
      appConfig
    }}>
      {children}
    </CustomLocalTranslationContext.Provider>
  );
}