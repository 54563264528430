import authHeaderFn from "@iso/utils/authHeaderFn";
import showNotification from "@iso/utils/notification";
import redirectLogin from "@iso/utils/redirectHelper";
import axios from "axios";
import get from "lodash/get";

export const getSubDomain = () => {
  const fullUrl = window.location.host
  //window.location.host is subdomain.domain.com
  const parts = fullUrl.split('.');
  const subDomain = parts[0];
  return subDomain;
}

// const authHeaderFn = (headers = {}) => {};

export const API_BASE_DOMAINS = {
  dev: {
    default: 'http://18.169.18.240',
    ATNM: 'http://18.169.18.240',
  },
  prod: {
    default: 'https://safetyqube.uksouth.cloudapp.azure.com',
    ATNM: 'https://atnmapi.qubertech.com',
  },
  uat: {
    default: 'https://atnmuat.api.qubertech.com',
    ATNM: 'https://atnmuat.api.qubertech.com',
  }
}

export const isProd = () => process.env.REACT_APP_ENV === 'production';

export const isUat = () => process.env.REACT_APP_ENV === 'staging';

export const isDev = () => !process.env.REACT_APP_ENV || process.env.REACT_APP_ENV === 'development';

export const getTrack = () => {
  if (isProd()) {
    return 'prod';
  }

  if (isUat()) {
    return 'uat';
  }

  return 'dev';
}

export const isAtnmSubdomain = () => [
  'dev-sq-atnm',
  'dashboard-atnm',
  'altasnim',
  'atnm-uat',
  'altasnim-quality'
].includes(getSubDomain());

export const getBaseApi = (track = getTrack()) => {

  const baseDomainDict = API_BASE_DOMAINS[track]

  if (isAtnmSubdomain()) {
    return baseDomainDict.ATNM;
  }

  return baseDomainDict.default;
};

export const createAxiosInstance = (suffix, track) => {
  const axiosInstance = axios.create({
    baseURL: `${getBaseApi(track)}${suffix}`,
    headers: {
      "Content-Type": "application/vnd.safetyqube.com+json",
    },
  });

  return axiosInstance;
};

export const createRequestHelpers = (axiosInstance) => {
  const requestObj = {
    getAuth: (url, params, headers = {}) => {
      return axiosInstance.get(url, { params, headers });
    },
    getWithOptions: (url, options) => {
      return axiosInstance.get(url, { ...options, headers: authHeaderFn(options.headers) });
    },
    get: (url, params, headers = {}, signal) => {
      return axiosInstance.get(url, { params, headers: authHeaderFn(headers), signal });
    },
    post: (url, data, headers = {}) => {
      return axiosInstance.post(url, data, { headers: authHeaderFn(headers) });
    },
    patch: (url, data, headers = {}) => {
      return axiosInstance.patch(url, data, { headers: authHeaderFn(headers) });
    },
    put: (url, data, headers = {}) => {
      return axiosInstance.put(url, data, { headers: authHeaderFn(headers) });
    },
    delete: (url, data, headers = {}) => {
      return axiosInstance.delete(url, { data, headers: authHeaderFn(headers) });
    },
  };

  return requestObj;
}


export const resolveRequest = async (requestPromise) => {
  let data = {};
  let redLoginVariable = localStorage.getItem("redLoginVariable");
  if (redLoginVariable === "true") {
    try {
      const result = await requestPromise;
      data = result?.data || {};
      data.statusCode = result?.status;
    } catch (e) {
      const errorData = get(e, "response.data");
      const status = get(e, "response.status");
      if (status === 500)
        showNotification("error", errorData?.message || "Action could not be performed, please retry or contact system admin");
      else if (status === 409 && typeof errorData === "object")
        showNotification("error", errorData.message, errorData.data);
      else if (status === 401) {
        showNotification("error", "Session expired, please login again");
        localStorage.setItem("redLoginVariable", false);
        redirectLogin();
      }

      data = typeof errorData === "object" ? errorData : {};
      data.error = true;
      data.statusCode = status;
    }
  }
  return data;
};
