import { createAxiosInstance, createRequestHelpers, isDev } from "../instanceHelpers";

const axiosInstance = createAxiosInstance(
  isDev() ? ':8080/v2/admin' : '/sqadmin/v2/admin'
);

const request = createRequestHelpers(axiosInstance);

export { resolveRequest } from '../instanceHelpers';

export default request;
