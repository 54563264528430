import { createAxiosInstance, createRequestHelpers, isDev } from "../instanceHelpers";


const axiosInstance = createAxiosInstance(
 isDev() ? ':8081' : '/squm'
);

const requestUM = createRequestHelpers(axiosInstance);

export { resolveRequest as resolveRequestUM } from '../instanceHelpers';

export default requestUM;



