import React, { useEffect, useState } from "react";
import { isAndroid, isIOS, isMobile, isTablet } from "react-device-detect";
import googlePlay from "@iso/assets/images/googleplaybadge.png";
import appleStore from "@iso/assets/images/appStore.png";
import { Provider } from "react-redux";
import GlobalStyles from "@iso/assets/styles/globalStyle";
import { store } from "./redux/store";
import Boot from "./redux/boot";
import Routes from "./router";
import AppProvider from "./AppProvider";
import SidebarProvider from "./sidebarContext";
import { useTranslation } from "react-i18next";
import jwtDecode from "jwt-decode";

import "./App.css";
import Backdrop from "./components/Backdrop";
import { CustomLocalTranslationProvider } from "./context/CustomLocalTranslationContext";
import { OhlNodeTranslationProvider } from "./context/OhlNodeTranslationContext";

const App = () => {
  const { i18n } = useTranslation();
  const token = localStorage.getItem("token");
  const tokenTimezone = token ? jwtDecode(token)["selectedTimezone"] : "";

  const [isOffline, setIsOffline] = useState(!window.navigator.onLine);

  const orientation = window.screen.orientation ? window.screen.orientation.type.split("-") : [];

  const isTabletDevice = isTablet || (isMobile && ((orientation[0] === "portrait" && window.innerWidth > 500) || (orientation[0] === "landscape" && window.innerHeight > 500)))

  useEffect(() => {
    if (isMobile && !isTabletDevice) {
      if (isAndroid) {
        window.location.replace(
          "https://play.google.com/store/apps/details?id=com.safetyqube"
        );    
      } else if (isIOS) {
          window.location.replace(
            "https://apps.apple.com/lt/app/safetyqube/id1576481295"
          );
      }
    }

    localStorage.setItem("redLoginVariable",true);

    window.addEventListener("offline", () => setIsOffline(true));
    window.addEventListener("online", () => setIsOffline(false));
  }, []);
  
  const renderUI = () => {
    if (isMobile && !isTabletDevice) {
      if (isAndroid) return (
        <div className="playLink">
            <img src={googlePlay} className="imgElement-app" alt="tile"></img>
        </div>
      );
      else if (isIOS) return (
        <div className="playLink">
            <img src={appleStore} className="imgElement-app" alt="tile"></img>
        </div>
      );
    }

    return (
      <Provider store={store}>
        <AppProvider>
          <CustomLocalTranslationProvider>
            <OhlNodeTranslationProvider>
              <>
                <SidebarProvider>
                  <GlobalStyles />
                  <Routes />
                  {isOffline && <Backdrop message="You're offline. Please connect to internet." />}
                </SidebarProvider>
              </>
            </OhlNodeTranslationProvider>
          </CustomLocalTranslationProvider>
        </AppProvider>
      </Provider>
    );
  }

  return (
    <div className="App h-100per">
      {renderUI()}
    </div>
  );
};
Boot()
  .then(() => App())
  .catch((error) => console.error(error));

export default App;
