import { isDev  } from "@iso/config/instanceHelpers";

const redirectLogin = () => {
  const redirectUri = encodeURIComponent(window.location.origin.replace("http://", "https://"));

  sessionStorage.setItem("recentURL", window.location.pathname)

  let loginUrl =
  `https://sqquber.b2clogin.com/sqquber.onmicrosoft.com/oauth2/v2.0/authorize?p=B2C_1_sq_deb_b2c_openid&client_id=4c026174-9b4e-4e2f-b026-e853810a26f7&nonce=defaultNonce&redirect_uri=http%3A%2F%2Flocalhost%3A3000%2Fauth&scope=openid&response_type=id_token&prompt=login`;
  if (process.env.REACT_APP_ENV === "local") {
    loginUrl =
    `https://sqquber.b2clogin.com/sqquber.onmicrosoft.com/oauth2/v2.0/authorize?p=B2C_1_sq_deb_b2c_openid&client_id=4c026174-9b4e-4e2f-b026-e853810a26f7&nonce=defaultNonce&redirect_uri=http%3A%2F%2Flocalhost%3A3000%2Fauth&scope=openid&response_type=id_token&prompt=login`;
  } else if (isDev()) {
    loginUrl = `https://sqatnm.b2clogin.com/sqatnm.onmicrosoft.com/oauth2/v2.0/authorize?p=B2C_1_sqatnm&client_id=7b30171a-a8bd-493f-96be-07dd38a1b44c&nonce=defaultNonce&redirect_uri=${redirectUri}%2Fauth&scope=openid&response_type=id_token&prompt=login`
  } else if (process.env.REACT_APP_ENV === "pre-production") {
    loginUrl =
      `https://sqquber.b2clogin.com/sqquber.onmicrosoft.com/oauth2/v2.0/authorize?p=B2C_1_sq_deb_b2c_openid&client_id=16378eaf-2fa5-4507-a44e-8dbc251dd028&nonce=defaultNonce&redirect_uri=${redirectUri}%2Fauth&scope=openid&response_type=id_token&prompt=login`;
  } else if (process.env.REACT_APP_ENV === "production") {
    loginUrl =
      `https://safetyqube.b2clogin.com/safetyqube.onmicrosoft.com/oauth2/v2.0/authorize?p=B2C_1_safeyqube&client_id=8fe26a1b-dd3c-4b72-bcc9-5159c48dbedb&nonce=defaultNonce&redirect_uri=${redirectUri}%2Fauth&scope=openid&response_type=id_token&prompt=login`;
  }

  localStorage.removeItem("token");
  window.location = loginUrl;
};

export default redirectLogin;
