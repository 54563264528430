import { facilityManagementApi, resolveRequest } from '@iso/config/api';
import React, { createContext, useEffect, useState } from 'react';

export const OhlNodeTranslationContext = createContext({});

export const OhlNodeTranslationProvider = ({ children }) => {
  const [translations, setTranslations] = useState({});
  const [isFetchingOhlNodeDefinition, setIsFetchingOhlNodeDefinition] = useState(false);

  const fetchDefinitions = async () => {
    setIsFetchingOhlNodeDefinition(true);

    const promise = facilityManagementApi.getDefinition();
    const { data = [], error } = await resolveRequest(promise);

    setIsFetchingOhlNodeDefinition(false);

    if (error) return;

    const translationMap = Object.entries(data).reduce((m, [key, value]) => {
      if (key.includes('node')) {
        let keyPart = key[0].toUpperCase() + key.slice(1).replace('Def', 'Name');

        m[`ohl${keyPart}`] = value;
      }

      return m;
    }, {});

    setTranslations(translationMap);
  }

  useEffect(() => {
    const token = localStorage.getItem('token');

    if (token) fetchDefinitions();
  }, [])

  return (
    <OhlNodeTranslationContext.Provider value={{
      translations,
      setTranslations,
      isFetchingOhlNodeDefinition,
      fetchOhlNodeTranslationDefinations: fetchDefinitions
    }}>
      {children}
    </OhlNodeTranslationContext.Provider>
  );
}