import i18n from "../i18n";

const authHeaderFn = (headers = {}) => {
  const lng = i18n.language;
  const lngHeader = {
    "Accept-Language": lng === "fr" ? "fr-FR" : lng,
  };

  const authorization = { Authorization: localStorage.getItem("token") };
  const modifyHeader = Object.assign({}, headers, authorization, lngHeader);
  return modifyHeader;
};

export default authHeaderFn;
