import jwtDecode from "jwt-decode";
import moment from "moment";
import { resolveRequestUM, userAuthApi, userManagementApi } from "@apiUM";
import { cloneDeep } from "lodash";

export const getTimestamp = (date) => new Date(date || Date.now()).getTime();

export const getFileredRoutes = (routes, permissions) => routes.filter(route => {
  let and = true;

  if (route.children) route.children = getFileredRoutes(route.children, permissions);

  (route.requiredPermissions || []).forEach(p => {
    if (Array.isArray(p)) {
      let or = false;
      p.forEach(p => or |= permissions[p]);

      and &= or;
    } else {
      and &= permissions[p];
    }
  });

  return and;
});

export const whitelistLanguages = ['en', 'fr'];

export const generateUniqueId = () => Math.random().toString(36).substring(2) + (new Date()).getTime().toString(36);

export const setTempKeys = (data = []) => data.map(d => ({ ...d, key: d.id || d.key || generateUniqueId() }));

export const removeTempKeys = (data = []) => data.map(d => {
  delete d.key;
  return d;
});

export const getTokenData = () => {
  const token = localStorage.getItem("token");
  return token ? jwtDecode(token) : {};
}

export const getLetterByNumber = (number) => {
  const letters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
  return letters[number];
}

export const dataURLtoFile = (dataurl, filename) => {
  let arr = dataurl.split(','),
    mime = arr[0].match(/:(.*?);/)[1],
    bstr = atob(arr[1]),
    n = bstr.length,
    u8arr = new Uint8Array(n);

  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }

  return new File([u8arr], filename, { type: mime });
}

export const datePickerReadableFormat = (value) => (
  value ? moment(value).format("MMM DD, YYYY") : null
);
export const objectToQueryString = (obj, dontSendEmpty = false) => {
  if (!obj) return '';
  return Object.keys(obj)
    .filter((key) => !dontSendEmpty || obj[key])
    .map((key) => `${key}=${obj[key]}`)
    .join('&');
};

export const getOwnersString = (owners = [], type, { firstName, lastName }) =>
  (type === "Checklist" ? `${firstName} ${lastName}` : owners.join(", ")) ||
  "-";

export const isToAction = (record) => {
  if (record?.type === "Checklist" || record?.type === "Inspection") return true;

  if (record?.type === "Hazard" || record?.type === "Incident") {
    if (
      (record?.isRaiser === true &&
        record?.status === "Resolution Approved by EHS Team") ||
      (record?.status === "Open" && record?.isOwner === true) ||
      (record?.isResolutionTeam === true &&
        (record?.status === "Resolution not Approved by EHS Team" ||
          record?.status === "Resolution not Approved by Raiser" ||
          record?.status === "Assigned")) ||
      (record?.status === "Resolution Implemented" &&
        record?.isEHSMember === true &&
        record?.isRaiser === null &&
        record?.isOwner === null &&
        record?.isResolutionTeam === null &&
        record?.isFYIMember === null)
    ) {
      return true;
    }
  }

  if (record?.type === "Task") {
    return record?.isOwner;
  }

  return false;
};


export const saveFiltersToSessionStorage = (filterKey = '', filters = {}) => {
  const filtersStr = JSON.stringify(filters);
  sessionStorage.setItem(filterKey, filtersStr);
}

export const getFiltersFromSessionStorage = (filterKey = '', defaultFilterState = {}) => {
  const filters = sessionStorage.getItem(filterKey);

  if (!filters) return defaultFilterState;

  const parsedFilters = JSON.parse(filters);
  return parsedFilters;
}

export const createSessionStorageFilterKey = (baseFilterKeyName = '') => {
  return `${baseFilterKeyName}`;
}

export const clearSessionStorageFilter = (filterKey = '') => sessionStorage.removeItem(filterKey);

export const setLanguageTimezone = async (token, lang) => {

  if (token) {
    const params = {}

    const tokenLanguage = jwtDecode(token)["selectedLanguage"];
    const tokenTimezone = jwtDecode(token)["selectedTimezone"];

    if (!tokenLanguage || tokenLanguage === "") {
      params["selectedLanguage"] = Intl.DateTimeFormat().resolvedOptions().locale.split("-")[0];
    } else if (whitelistLanguages.indexOf(tokenLanguage) >= 0) {
      lang.changeLanguage(tokenLanguage);
    } else {
      lang.changeLanguage("en");
    }

    if (!tokenTimezone || tokenTimezone === "") {
      params["selectedTimezone"] = Intl.DateTimeFormat().resolvedOptions().timeZone;
    }

    if (Object.keys(params).length > 0) {
      params["id"] = jwtDecode(token)["userId"];
      params["roleId"] = jwtDecode(token)["roleId"];

      const promise = userManagementApi.updateEmployee(params);
      const response = await resolveRequestUM(promise);
    }
  }
}

export const capitalizeFirstLetter = (string = '') => {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export const removeKeysFromNestedArray = (list = [], lookInToKeys = [], keysToRemove = []) => {
  const mapAndRemoveId = (list) => {
    return list.map(item => {
      if (typeof item === "object") {
        keysToRemove.forEach(key => delete item[key]);
      }

      lookInToKeys.forEach(lookInToKey => {
        const { name, type } = lookInToKey;

        if (type === "array" && Array.isArray(item[name])) {
          item[name] = mapAndRemoveId(item[name]);
        } else if (type === "object" && typeof item[name] === "object") {
          keysToRemove.forEach(key => delete item[name][key]);
          Object.keys(item[name]).forEach(key => {
            if (Array.isArray(item[name][key])) {
              item[name][key] = mapAndRemoveId(item[name][key]);
            }
          });
        }
      });

      return item;
    });
  }

  return mapAndRemoveId(cloneDeep(list));
}

export const getSearchParamsUrlStr = (baseUrl = '', data = {}) => {
  let url = baseUrl;

  Object.keys(data).forEach((key, index, arr) => {
    const value = data[key];
    if (index === 0) url += '?';
    url += `${key}=${value}`;
    if (arr.length - 1 !== index) url += '&';
  })

  return url.toString();
}

export const isValidJSONString = (str) => {
  try {
    JSON.parse(str);
  } catch (e) {
    return false;
  }
  return true;
}

export const safeJsonParse = (str, defaultValueToReturn = {}) => {
  try {
    if (!str) return defaultValueToReturn;
    return JSON.parse(str);
  } catch (e) {
    return defaultValueToReturn;
  }
}


export const getTimezoneName = (timezone = "") => {
  const name = typeof timezone === "string" && timezone.includes("/") ? timezone : Intl?.DateTimeFormat()?.resolvedOptions()?.timeZone;

  if (typeof name === "string") return name.split("/").join(" / ");

  return name;
}

export const getTimezoneOffset = (timezone = "") => {
  if (!timezone) {
    const offset = new Date().getTimezoneOffset() / 60;
    return "GMT " + (offset > 0 ? "-" : "+") + Math.abs(offset);
  }

  const now = new Date();

  const offsetInMinutes = now.getTimezoneOffset() + new Date(now.toLocaleString("en-US", { timeZone: timezone })).getTimezoneOffset();
  const offsetInHours = offsetInMinutes / 60;
  const offsetSign = offsetInHours < 0 ? "-" : "+";
  const offsetInHoursAbs = Math.abs(offsetInHours);

  const offsetFormatted = `GMT ${offsetSign}${offsetInHoursAbs}`;

  return offsetFormatted;
}

export const getTimezoneNameAndOffset = (selectedTimezone) => {
  return `${getTimezoneName(selectedTimezone)}; ${getTimezoneOffset(selectedTimezone)}`;
};

export const getWeeksInMonth = (
  date = new Date(),
) => {
  const month = date.getMonth();
  const year = date.getFullYear();

  // Using the given month and year, get the first date of the month
  const startOfMonth = new Date(year, month, 1);
  const endOfMonth = new Date(year, month + 1, 0); // Last day of the month

  // Set the start date as the first date of the month
  let startDateOfWeek = new Date(startOfMonth);

  let weeks = [];

  while (startDateOfWeek <= endOfMonth) {
    // Calculate the end date of the week
    let endDateOfWeek = new Date(startDateOfWeek);
    endDateOfWeek.setDate(startDateOfWeek.getDate() + 6 - startDateOfWeek.getDay()); // 6 (Saturday) - current day

    if (endDateOfWeek > endOfMonth) {
        endDateOfWeek = endOfMonth;
    }

    weeks.push({ startDateOfWeek: new Date(startDateOfWeek), endDateOfWeek: new Date(endDateOfWeek) });

    // Move to the next Sunday for the start date of the next week
    startDateOfWeek = new Date(endDateOfWeek);
    startDateOfWeek.setDate(endDateOfWeek.getDate() + 1);
  }

  return weeks;
}

export const isRangeWithinRange = (checkStart, checkEnd, rangeStart, rangeEnd) => {
  const checkStartDate = moment(checkStart);
  const checkEndDate = moment(checkEnd);
  const rangeStartDate = moment(rangeStart);
  const rangeEndDate = moment(rangeEnd);

  return (
    checkStartDate.isBetween(rangeStartDate, rangeEndDate, 'day', '[]') &&
    checkEndDate.isBetween(rangeStartDate, rangeEndDate, 'day', '[]')
  );
}