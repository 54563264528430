import { objectToQueryString } from "@iso/utils/helpers";
import requestUM from "./apiUM";
// const prefix = "http://100.24.253.101:8081";
const userManagementApi = {
  // getEmployees: () => requestUM.get(prefix + "/aum/um/"),
  getEmployees: (params) => requestUM.get("/aum/um/", params),
  refreshUser: (employeeId) => requestUM.put(`/aum/um/employee/refresh/${employeeId}`),
  getEmployeesBrief: (params = {}) => requestUM.get("/aum/um/brief", params),
  getEmployeesByPrivilege: (privilegeName) =>
    requestUM.get(`/aum/um/privilege?privilegeName=${privilegeName}`),
  createEmployee: (data) => requestUM.post("/aum/um/employee", data),
  updateEmployee: (data) => requestUM.patch("/aum/um/employee", data),
  bulkUploadEmployee: (data) => requestUM.post("/aum/um/employee/bulk", data),
  postEmployeeDP: (employeeId, data) =>
    requestUM.post(`/aum/um/employee/dp/${employeeId}`, data),
  manageNotification: (id, data) =>
    requestUM.patch(`/aum/um/managenotification/${id}`, data),
  employeeStatus: (data = {}) =>
    requestUM.put(`/aum/um/status?${objectToQueryString(data)}`),
  delegateUser: (data) => requestUM.put(`/aum/um/delegate?${objectToQueryString(data)}`),
  deleteEmployee: (employeeId, delegatedEmployeeId) =>
    requestUM.delete(
      `/aum/um?employeeId=${employeeId}&delegatedEmployeeId=${delegatedEmployeeId}`
    ),
  getEmployeeID: (employeeId) => requestUM.get(`/aum/um/${employeeId}`),
  getNotificationID: (employeeId) =>
    requestUM.get(`/aum/um/managenotification/${employeeId}`),
  getEmployeeNotification: () => requestUM.get("/aum/notification/"),
  putEmployeeNotificationRead: (notificationId) =>
    requestUM.put(`/aum/notification/${notificationId}`),
  putNotificationRead: () => requestUM.put(`/aum/notification`),
  getTrainingTileURL: () => requestUM.get("/aum/um/training/link"),
};

export default userManagementApi;
